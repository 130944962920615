/**
 * @prettier
 * @flow
 */
import React from 'react';
import CTAButton from '../CTAButton';
import * as UI from '../UI/styles';
import * as S from './styles';

const NewsletterForm = () => (
    <form
        method="GET"
        action="https://blockchaincopywriter.us10.list-manage.com/subscribe"
    >
        <input type="hidden" name="u" value="6dfebc12538bf9d7fcd41cea4" />
        <input type="hidden" name="id" value="70ab192fad" />

        <S.TextInput
            type="text"
            name="FNAME"
            placeholder="Your full name"
            required
        />
        <UI.Spacer size="s" />
        <S.TextInput
            type="email"
            name="EMAIL"
            placeholder="Your email"
            required
        />

        <UI.Spacer size="s" />
        <S.ButtonWrapper>
            <CTAButton type="submit">
                Subscribe
            </CTAButton>
        </S.ButtonWrapper>
    </form>
);

export default NewsletterForm;
